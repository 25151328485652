
import { createContext, useEffect, useState, useRef, useMemo, useContext } from 'react'
import { InputType } from '../Enums';
import { Vector3 } from "three";
import { useNavigate } from 'react-router-dom';
import { validateJson } from '../utils/jsonSchemaTools';
import { useApis } from './ApiServiceContext';
import { LayoutContext } from './LayoutContext';
import { useAssetServices } from "../services";
import { LockableMeasure } from "../utils/LockableMeasure";
import { getAssetTypeColor } from '../Enums/AssetTypeColor';

export const StoreContext = createContext(null)

const StoreProvider = ({ children }) => {
  const { assetApi, userApi, workflowStatusApi } = useApis();
  const { saveAsset, } = useAssetServices();
  const navigate = useNavigate();
  const layoutContext = useContext(LayoutContext);
  const [, setSnackbar] = layoutContext.snackbar;
  const [, setLoading] = layoutContext.loading;
  const [useImperial, setUseImperial] = useState(true);
  const [users, setUsers] = useState();
  const [workflowStatuses, setWorkflowStatuses] = useState();
  const [assetTypes, setAssetTypes] = useState();
  const [assetSchemasDict, setAssetSchemasDict] = useState({});
  const [poles, setPoles] = useState([]);
  const [dropdownData, setDropdownData] = useState(null);
  const [imageId, setImageId] = useState();
  const [map, setMap] = useState();

  const [potreeViewer, setPotreeViewer] = useState(null);

  const [activeProject, setActiveProject] = useState(null);
  const [availableProjects, setAvailableProjects] = useState([]);
  const [activePointcloud, setActivePointcloud] = useState(null);
  const [potreeMode, setPotreeMode] = useState(false);
  const [markerPlacement, setMarkerPlacement] = useState([]);
  const [groundCollectMode, setGroundCollectMode] = useState(false);
  const [activePole, setActivePole] = useState(null);
  const activePoleRef = useRef();
  const [relatedAssets, setRelatedAssets] = useState(null);
  const relatedAssetsRef = useRef();
  relatedAssetsRef.current = relatedAssets;
  const [updatedRelatedAssets, setUpdatedRelatedAssets] = useState([]);
  const spanAttachmentPoints = useMemo(() => {
    return relatedAssets?.filter(asset => asset.asset_archetype_id === 8) || [];
  }, [relatedAssets]);

  const [measurementMap, setMeasurementMap] = useState({});
  const [validationErrors, setValidationErrors] = useState([]);
  const [updatedPoleFormData, setUpdatedPoleFormData] = useState({});
  const [polesUpdateTrigger, setPolesUpdateTrigger] = useState(false);

  const addToUpdatedRelatedAssets = (updatedAsset) => {
    if (relatedAssetsRef.current && updatedAsset) {

      setUpdatedRelatedAssets((prev) => {
        let existingAssetIndex = prev.findIndex(asset => updatedAsset.asset_uuid ? asset.asset_uuid === updatedAsset.asset_uuid : asset.id === updatedAsset.id);
        let originalAsset = relatedAssets?.find(asset => updatedAsset.asset_uuid ? asset.asset_uuid === updatedAsset.asset_uuid : asset.id === updatedAsset.id);
        let addToList = existingAssetIndex === -1 && JSON.stringify(updatedAsset) !== JSON.stringify(originalAsset);

        if (addToList) {
          return [...prev, { ...updatedAsset }];
        } else {
          prev.splice(existingAssetIndex, 1, { ...updatedAsset });
          return prev.slice(); // returning a new array reference to trigger React's state update
        }
      });
    }
  }

  const saveAssetLocal = async (duplicate = false) => {
    setLoading(true);
    try {
      if (!duplicate) {
        // updateSpanOwners(updatedRelatedAssets);
        // validateAsset(updatedRelatedAssets);
      }
      addObjectMetadataToAsset(activePole);
      let updatedAsset = await saveAsset(activePole, true);
      await saveRelatedAssets(duplicate ? relatedAssets || [] : updatedRelatedAssets);
      setActivePole(updatedAsset);
      await loadAssetIntoViewer();
      setSnackbar({
        open: true,
        message: "Pole and its attachments updated successfully",
        severity: 'success',
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.message,
        severity: 'error',
      });
    }
    setLoading(false);
  };

  const saveRelatedAssets = async (assets) => {
    const updatePromises = assets.map(asset => {
      return saveAsset(asset, true);
    });

    await Promise.all(updatePromises).then(values => setUpdatedRelatedAssets(values));
  }

  const updateSpanOwners = (assets) => {
    if (spanAttachmentPoints.length > 0) {
      assets.map(asset => {
        const isSpanAttachmentPoint = spanAttachmentPoints.some(point => point.source_sys_id === asset.source_sys_id);
        if (isSpanAttachmentPoint) {
          let relatedSpans = relatedAssets?.filter(relatedAsset =>
            relatedAsset.asset_properties.attached_assets?.some(attached =>
              attached.attached_asset_source_sys_id === asset.source_sys_id));
          relatedSpans?.forEach(span => {
            span.asset_properties.owner = asset.asset_properties.owner;
            assets.push(span);
          });
        }
        return asset;
      });
    }
  }

  const addObjectMetadataToAsset = (asset) => {
    asset.asset_properties.pointcloud_def = activePointcloud;
    asset.asset_properties.image_id = imageId;
  }

  const validateAsset = (updatedRelatedAssets) => {
    const errorMessages = {};

    const addUniqueErrors = (assetType, errors) => {
      if (errors) {
        if (!errorMessages[assetType]) {
          errorMessages[assetType] = [];
        }
        errors.forEach(error => {
          if (!errorMessages[assetType].includes(error.message)) {
            errorMessages[assetType].push(error.message);
          }
        });
      }
    };

    let assetErrors = validateJson(
      assetSchemasDict[activePole.asset_properties_json_schema_id].json_schema_definition,
      activePole.asset_properties
    );
    addUniqueErrors(`${activePole.asset_archetype_name}.${activePole.asset_type_name}`, assetErrors);

    updatedRelatedAssets.forEach(asset => {
      let relatedAssetErrors = validateJson(
        assetSchemasDict[asset.asset_properties_json_schema_id].json_schema_definition,
        asset.asset_properties
      );
      if (relatedAssetErrors && relatedAssetErrors.length > 0) {
        let assetType = assetTypes.find(type => type.asset_type_id === asset.asset_type_id)
        addUniqueErrors(`${assetType.asset_archetype_name}.${assetType.asset_type_name}`, relatedAssetErrors);
      }
    });

    if (Object.keys(errorMessages).length > 0) {
      const formattedErrorMessage = Object.entries(errorMessages).reduce((acc, [key, value], index, array) => {
        const endOfLine = index < array.length - 1 ? ',\n' : '';
        return `${acc}"${key}": ${JSON.stringify(value, null, 2)}${endOfLine}`;
      }, '{\n') + '\n}';

      throw Error(formattedErrorMessage);
    }
  }

  const loadAssetIntoViewer = async () => {
    potreeViewer.scene.removeAllMeasurements();
    let assetCatalog = assetSchemasDict[1].json_schema_definition;
    loadNestedAssetsIntoViewer(assetCatalog.properties, activePole)
    let attachedAssets = await assetApi.getAttachedAssets([activePole.source_sys_id], activePole.input_source_type_id);
    let types = assetTypes;
    attachedAssets?.forEach(async (asset) => {
      if (asset.asset_type_id) {
        let schemaId = types.find(type => type.asset_type_id === asset.asset_type_id)?.asset_properties_json_schema_id;
        assetCatalog = assetSchemasDict[schemaId].json_schema_definition;
        assetCatalog
          ? loadNestedAssetsIntoViewer(assetCatalog.properties, asset)
          : setSnackbar({
            open: true,
            message: `Schema not found for ${asset.asset_type_id}`,
            severity: 'error',
          });
      }
      // Sort the attached assets so that the parent asset comes first
      if (asset.asset_properties && asset.asset_properties.attached_assets) {
        asset.asset_properties.attached_assets.sort((a, b) => {
          if (a.attached_asset_source_sys_id === activePole.source_sys_id) return -1;
          if (b.attached_asset_source_sys_id === activePole.source_sys_id) return 1;
          return a.attached_asset_source_sys_id.localeCompare(b.attached_asset_source_sys_id);
        });
      }
    })?.reduce((acc, current) => {
      const x = acc.find(item => item.asset_uuid === current.asset_uuid);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    setRelatedAssets(attachedAssets);
  }

  const getNestedPropertyValue = (obj, nestedKey) => {
    const keys = nestedKey.split('.');
    return keys.reduce((o, key) => {
      if (o && o !== undefined) {
        const match = key.match(/^(\w+)\[(\d+)\]$/); // Matches "key[index]"
        if (match) {
          return o[match[1]] !== undefined ? o[match[1]][match[2]] : undefined;
        }
        return o[key];
      }
      return undefined;
    }, obj);
  }

  const loadNestedAssetsIntoViewer = async (properties, assetObject, parentKey = '') => {
    Object.entries(properties).forEach(([key, prop]) => {
      const currentKey = parentKey ? `${parentKey}.${key}` : key;
      let type = Array.isArray(prop.type) ? prop.type[0] : prop.type;

      if (type === InputType.Object && prop.object_format === 'pointcloud_coordinates_object') {
        let propValue = getNestedPropertyValue(assetObject.asset_properties, currentKey);
        if (propValue) {
          loadMeasurement(
            `${currentKey}_${assetObject.asset_uuid}`,
            propValue,
            getAssetTypeColor(assetObject)
          );        
        }
      } else if (type === InputType.Array && prop.items) {
        // Handle array case, iterate over each item
        let arrayValue = getNestedPropertyValue(assetObject.asset_properties, currentKey);
        if (Array.isArray(arrayValue) && prop.items.type === 'object') {
          arrayValue.forEach((_, index) => {
            loadNestedAssetsIntoViewer(prop.items.properties, assetObject, `${currentKey}[${index}]`);
          });
        }
      } else if (prop.items?.properties) {
        // Recurse into nested properties
        loadNestedAssetsIntoViewer(prop.items.properties, assetObject, currentKey);
      } else if (type === InputType.Object && prop.properties) {
        loadNestedAssetsIntoViewer(prop.properties, assetObject, currentKey);
      }
    });
  };

  const loadMeasurement = (measurementLabel, coords, color) => {
    let measure = new LockableMeasure(potreeViewer, color);
    measure.name = measurementLabel;
    measure.showDistances = false;
    measure.showCoordinates = false;
    measure.maxMarkers = 1;
    measure.addMarker(new Vector3(coords.x, coords.y, coords.z));
    potreeViewer.scene.addMeasurement(measure);
    // need to set the map intially since there are no drop events happening here. 
    setMeasurementMap((prevMap) => {
      return {
        ...prevMap,
        [measurementLabel]: measure
      }
    });
    measure.addEventListener('marker_dropped', (event) => {
      setMeasurementMap((prevMap) => {
        return {
          ...prevMap,
          [measurementLabel]: measure
        }
      });
    });
  }

  const fetchAssetTypes = async () => {
    let types = await assetApi.getAssetTypes();
    setAssetTypes(types);
    return types;
  }
  const getSchemasToDictionary = async () => {
    let queryResults = await assetApi.getJsonSchemaDefinitions();
    queryResults = Array.isArray(queryResults) ? queryResults : [];
    return queryResults.reduce((acc, current) => {
      const { json_schema_id, ...otherProperties } = current;
      acc[json_schema_id] = otherProperties;
      return acc;
    }, {});
  };

  useEffect(() => {
    const getDict = async () => {
      setAssetSchemasDict(await getSchemasToDictionary());
    }

    getDict();
    fetchAssetTypes();
  }, [])

  useEffect(() => {
    const getData = async () => {
      setUsers(await userApi.fetchUsers());
      setWorkflowStatuses(await workflowStatusApi.fetchWorkflowStatuses());
    }
    if (!users || !workflowStatuses) {
      getData();
    }
  }, [users, workflowStatuses])

  useEffect(() => {
    if (activePole && activePole.asset_uuid) { // Check if activePole is not null and has asset_uuid
      const currentUrlParams = new URLSearchParams(window.location.search);
      currentUrlParams.set('pole_id', activePole.asset_uuid);
      const currentPoleId = activePole.id || activePole.asset_uuid;
      if (activePoleRef.current !== currentPoleId && potreeViewer) {
        loadAssetIntoViewer();
        activePoleRef.current = currentPoleId;
      }
      navigate(`${window.location.pathname}?${currentUrlParams.toString()}`, { replace: true });
    }
  }, [activePole, navigate, potreeViewer]);

  useEffect(() => {
    if (imageId) {
      const currentUrlParams = new URLSearchParams(window.location.search);
      currentUrlParams.set('image_id', imageId);
      navigate(`${window.location.pathname}?${currentUrlParams.toString()}`, { replace: true });
    }
  }, [imageId])

  const store = {
    useImperial: [useImperial, setUseImperial],
    users: [users, setUsers],
    workflowStatuses: [workflowStatuses, setWorkflowStatuses],
    poles: [poles, setPoles],
    assetTypes: [assetTypes, setAssetTypes],
    assetSchemasDict: [assetSchemasDict, setAssetSchemasDict],
    projects: [availableProjects, setAvailableProjects],
    potreeViewer: [potreeViewer, setPotreeViewer],
    activeProject: [activeProject, setActiveProject],
    availableProjects: [availableProjects, setAvailableProjects],
    activePointcloud: [activePointcloud, setActivePointcloud],
    activePole: [activePole, setActivePole],
    relatedAssets: [relatedAssets, setRelatedAssets],
    updatedRelatedAssets: [updatedRelatedAssets, setUpdatedRelatedAssets],
    dropdownData: [dropdownData, setDropdownData],
    measurementMap: [measurementMap, setMeasurementMap],
    potreeMode: [potreeMode, setPotreeMode],
    markerPlacement: [markerPlacement, setMarkerPlacement],
    groundCollectMode: [groundCollectMode, setGroundCollectMode],
    imageId: [imageId, setImageId],
    map: [map, setMap],
    updatedPoleFormData: [updatedPoleFormData, setUpdatedPoleFormData],
    validationErrors: [validationErrors, setValidationErrors],
    polesUpdateTrigger: [polesUpdateTrigger, setPolesUpdateTrigger],
    addToUpdatedRelatedAssets,
    saveAssetLocal,
    loadAssetIntoViewer,
    getNestedPropertyValue
  }

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}

export default StoreProvider;