import { signInWithRedirect, fetchAuthSession } from 'aws-amplify/auth';

export const httpRequest = async (url, isSecure = false, options = {}) => {

    try {
        let defaultHeaders = {
        //     // "Content-Type": "application/json",
        //     // TODO: Add the following header support in AWS API gateway to allow using them:
        //     // "Access-Control-Request-Method": "GET",
        //     //credentials: 'omit'
        };
        if (isSecure) {
            let auth = null;
            await fetchAuthSession().then(async (data) => {
                    if (!data?.tokens) {
                        await signInWithRedirect({
                            provider: {
                                custom: "azureCognitoDevIdp"
                            }
                        })
                            .then((data) => {
                                auth = data;
                            });
                    }
                    else {
                        auth = data;
                    }
                })
                .catch((err) => {
                    // TODO: Handle error
                    console.error('AUTH::fetchAuthSession Error', err);
                });

            defaultHeaders = {
                "Content-Type": "application/json",
                "Access-Control-Request-Method": options.method,
                //credentials: 'include',
                //Authorization: `Bearer ${tokenResponse.accessToken}`,
                authorizationToken: auth?.tokens?.accessToken?.toString()
                //authorizationToken: ''
            };

        } // End of isSecure

        const config = {
            ...options,
            headers: {
                ...defaultHeaders,
                ...options.headers, // Allow overriding default headers
            },
        };

        const response = await fetch(url, config);

        // Check if the response is OK
        if (!response.ok) {

            const error = await response.json();
            throw new Error(error.message || "Something went wrong");
        }

        // let callers handle how to parse the response
        return response;

    } catch (error) {

        console.error("Fetch error:", error.message);

        // Redirect to login if token acquisition fails
        if (error.message.includes("No accounts found") || error.name === "InteractionRequiredAuthError") {

            console.warn('Authentication-related error, redirecting to login...');
            //msalInstance.loginRedirect(loginRequest);
        }

        throw error;
    }
};

export const httpGet = async (url, isSecure = false, params = {}) => {
    return await httpRequest(url, isSecure, { method: 'GET', ...params }, );
}

export const httpPost = async (url, isSecure = false, params = {}) => {
    return await httpRequest(url, isSecure, { method: 'POST', ...params }, );
}

export const httpPut = async (url, isSecure = false, params = {}) => {
    return await httpRequest(url, isSecure, { method: 'PUT', ...params }, );
}

export const httpDelete = async (url, isSecure = false, params = {}) => {
    return await httpRequest(url, isSecure, { method: 'DELETE', ...params }, );
}
