import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { StoreProvider, PositionProvider } from './Contexts';
import { BrowserRouter } from 'react-router-dom';
import { ApiProvider } from './Contexts/ApiServiceContext';
import LayoutProvider from './Contexts/LayoutContext';

// Auth
//import { PublicClientApplication, EventType } from "@azure/msal-browser";
//import { msalConfig, loginRequest } from './api/auth/authConfig';
//export const msalInstance = new PublicClientApplication(msalConfig);


/*

msalInstance.initialize().then(() => {

  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {

    // Account selection logic is app dependent. TODO: Adjust as needed for different use cases.
    // Also - in Feb 2025 Microsoft will change the way AAD authentication works - last accountlogged into will be used automatically until logged out. TODO: Check in Feb and implement "Logout" functionality. 
    console.log('Setting active account to index 0 from ', msalInstance.getAllAccounts());
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {

    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;

      console.warn('Setting active account to ', account);
      msalInstance.setActiveAccount(account);
    }
  });

  //console.log('msalConfig', msalConfig);

  // handle auth redirected/do all initial setup for msal
  msalInstance.handleRedirectPromise().then(authResult=>{
    
    // Check if user signed in 
    const account = msalInstance.getActiveAccount();
    if(!account){
      // redirect anonymous user to login page 
      msalInstance.loginRedirect(loginRequest);
    }
    else {

      // DEBUG - TODO: remove all references to: 
      //const currentUser = JSON.parse(localStorage.getItem('currentUser')); 
      // and get user via MSAL
      // For now:
      localStorage.setItem('currentUser', JSON.stringify(account));

    }
  }).catch(err=>{
    // TODO: Handle errors
    console.error(err);
  });

*/

  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    // <React.StrictMode>
      <BrowserRouter>
        <LayoutProvider>
          <ApiProvider>
            <StoreProvider>
              <PositionProvider>
                <App />
              </PositionProvider>
            </StoreProvider>
          </ApiProvider>
        </LayoutProvider>
      </BrowserRouter>
    // </React.StrictMode>
  );

//});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
