import { httpGet } from "./auth/HttpClient";

const baseUrl = process.env.REACT_APP_BASE_APPLICATION_API_URL;
const useSecureApi = true;

const fetchUsers = async () => {
    const url = `${baseUrl}/get_users`;
    return await httpGet(url, useSecureApi)
        .then(response => response.json())
        .then(data => { return data })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

export {
    fetchUsers,
}