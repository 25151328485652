import { httpGet } from "./auth/HttpClient";

const baseUrl = process.env.REACT_APP_BASE_IMAGE_METADATA_API_URL;
const useSecureApi = true;

const fetchProjectList = async () => {
  const url = `${baseUrl}/get_collection_location_data`;//_test_auth2`; // get_roles_projects_by_user`; //get_collection_location_data

  return await httpGet(url, useSecureApi)
        .then(response => {return response.json()})
        .then(data => { return data.body })
        .catch(error => { 
            throw new Error(`get_collection_image_point_next API call failed with status code ${error}`) 
        });
}

export {
  fetchProjectList,
}
