import { httpGet, httpPost } from "./auth/HttpClient";

const baseUrl = process.env.REACT_APP_BASE_APPLICATION_API_URL;
const useSecureApi = true;

const getGroundCollectionDropdowns = async () => {
    const url = `${baseUrl}/get_ground_collect_reasons`;
    return await httpGet(url, useSecureApi)
        .then(response => response.json())
        .then(data => data)
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

const createGroundRequestCollection = async (data) => {
    const url = `${baseUrl}/create_ground_collect_request`;
    const headers = {
        'Content-Type': 'application/json'
    };
    return await httpPost(url, useSecureApi, {
        headers: headers,
        body: JSON.stringify(data)
    })
        .then(response => response.json())
        .then(data => { return data.body })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

export {
    getGroundCollectionDropdowns,
    createGroundRequestCollection
}