import { httpGet } from "./auth/HttpClient";

const baseUrl = process.env.REACT_APP_BASE_IMAGE_METADATA_API_URL;
const useSecureApi = true;

const fetchImageryByBounds = async (collectionName, gpsx1, gpsy1, gpsx2, gpsy2) => {
    if (gpsx1) {
        const url = `${baseUrl}/get_collection_image_data_boundingbox?collection_name=${collectionName}&gpsx1=${gpsx1}&gpsy1=${gpsy1}&gpsx2=${gpsx2}&gpsy2=${gpsy2}`;
        return await httpGet(url, useSecureApi)
            .then(response => response.json())
            .then(data => { return data.body })
            .catch(error => { throw new Error(`API call failed with status code ${error}`) });
    }
};

const fetchImageryByProject = async (projectName, maxRecords = null, prefilter = null) => {
    const url = `${baseUrl}/get_collection_image_data_by_project_name?project_name=${projectName}` +
        (maxRecords ? `&max_records=${maxRecords}` : '') + // default and absolute max is 5,000
        (prefilter ? `&prefilter=${prefilter}` : ''); // selects nth values
    return await httpGet(url, useSecureApi)
        .then(response => response.json())
        .then(data => { return data.body })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
};

const fetchPanoImageData = async (imageId) => {
    const url = `${baseUrl}/get_collection_image_data_by_id?image_id=${imageId}`;
    return await httpGet(url, useSecureApi)
        .then(response => response.json())
        .then(data => { return data.body[0] })
        .catch(error => { throw new Error(`get_collection_image_data_by_id API call failed with status code ${error}`) });
}

const fetchPanoImage = async (imageFolder, imageFilename, bucketName) => {
    const fileLocation = encodeURIComponent(`${imageFolder}${imageFilename}`);
    const url = `${baseUrl}/get_presigned_image_url?bucket_name=${bucketName}&image_url=${fileLocation}`;
    return await httpGet(url, useSecureApi)
        .then(response => response.json())
        .then(data => { return JSON.parse(data['body']) })
        .catch(error => { throw new Error(`get_presigned_image_url API call failed with status code ${error}`) });
}

const fetchNearestPanoImage = async (collection_name, latitude, longitude) => {
    const url = `${baseUrl}/get_collection_image_closest_point?` +
        `collection_name=${collection_name}` +
        `&lat=${latitude}` +
        `&lng=${longitude}`
    return await httpGet(url, useSecureApi)
        .then(response => response.json())
        .then(data => { return data.body[0] })
        .catch(error => { throw new Error(`get_collection_image_closest_point API call failed with status code ${error}`) });
}


const fetchNextPreviousPanoImages = async (collection_name, image_id, point_of_interest) => {

    // DEBUG
    // return [
    //     {
    //     "id": 812687,
    //     "collection_name": "Georgia_Power_03",
    //     "collection_id": "93",
    //     "collection_set": "3934",
    //     "Latitude_deg": 34.3812325461,
    //     "Longitude_deg": -85.1359847883,
    //     "Heading_deg": null,
    //     "Frame_folder": "Georgia_Power_03/93/3934/panoramas/",
    //     "Frame_filename": "00001.jpg",
    //     "Frame_bucket_name": "geospatial-looq",
    //     "point_of_interest": 0,
    //     "created_date": "2024-12-13 23:43:21"
    //     },
    //     {
    //     "id": 812689,
    //     "collection_name": "Georgia_Power_03",
    //     "collection_id": "93",
    //     "collection_set": "3934",
    //     "Latitude_deg": 34.3812320442,
    //     "Longitude_deg": -85.1359847058,
    //     "Heading_deg": null,
    //     "Frame_folder": "Georgia_Power_03/93/3934/panoramas/",
    //     "Frame_filename": "00003.jpg",
    //     "Frame_bucket_name": "geospatial-looq",
    //     "point_of_interest": 0,
    //     "created_date": "2024-12-13 23:43:22"
    //     }
    // ];

    
    const url = `${baseUrl}/get_collection_image_point_next?` +
        `collection_name=${collection_name}` +
        `&image_id=${image_id}` +
        `&point_of_interest=${point_of_interest}`;
  
        return await httpGet(url, useSecureApi)
        .then(response => response.json())
        .then(data => { return data.body })
        .catch(error => { throw new Error(`get_collection_image_point_next API call failed with status code ${error}`) });    
}


export {
    fetchImageryByBounds,
    fetchImageryByProject,
    fetchPanoImage,
    fetchPanoImageData,
    fetchNearestPanoImage,
    fetchNextPreviousPanoImages
}