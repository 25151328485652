import { httpGet } from "./auth/HttpClient";

const baseUrl = process.env.REACT_APP_BASE_IMAGE_METADATA_API_URL;
const useSecureApi = true;

const fetchPointCloudsByBounds = async (collectionName, gpsx1, gpsy1, gpsx2, gpsy2) => {
    if (gpsx1) {
        const url = `${baseUrl}/get_pointcloud_data_boundingbox?project_name=${collectionName}&gpsx1=${gpsx1}&gpsy1=${gpsy1}&gpsx2=${gpsx2}&gpsy2=${gpsy2}`;
        return await httpGet(url, useSecureApi)
            .then(response => response.json())
            .then(data => { return data.body })
            .catch(error => { throw new Error(`API call failed with status code ${error}`) });
    }
}

const fetchPointCloudsByProject = async (projectName) => {
    const url = `${baseUrl}/get_pointcloud_data_by_project?project_name=${projectName}`;
    return await httpGet(url, useSecureApi)
        .then(response => response.json())
        .then(data => { return data.body })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}


const fetchPointCloudByGPSLocation = async (collectionName, latitude, longitude) => {
    const url = `${baseUrl}/get_pointcloud_by_gps_location?collection_name=${collectionName}&lat=${latitude}&lng=${longitude}`;
    return await httpGet(url, useSecureApi)
        .then(response => response.json())
        .then(data => { return data.body })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

const fetchPointcloudMetadataJsonUrl = async (collection_name, collection_id, collection_set, point_cloud_filename) => {
    const url = `${baseUrl}/get_pointcloud_potree_view?collection_name=${collection_name}&collection_id=${collection_id}&collection_set=${collection_set}&point_cloud_filename=${point_cloud_filename}`;
    return await httpGet(url, useSecureApi)
        .then(response => response.json())
        .then(data => { return data.body })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

const fetchAdjacentPointcloudTiles = async (pointcloudId, numberOfTiles) => {
    const url = `${baseUrl}/get_pointcloud_potree_view_by_id?point_cloud_id=${pointcloudId}&adjacent_tiles=${numberOfTiles}`;
    return await httpGet(url, useSecureApi)
        .then(response => response.json())
        .then(data => { return data.body })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

export {
    fetchPointCloudsByBounds,
    fetchPointCloudsByProject,
    fetchPointCloudByGPSLocation,
    fetchPointcloudMetadataJsonUrl,
    fetchAdjacentPointcloudTiles
}