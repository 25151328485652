/* eslint-disable */
import { Routes, Route } from "react-router-dom";
import './App.scss';
import Layout from './Layout';
import ProjectList from './features/project-list/ProjectList';
import MapOne from "./app-views/MapOne";
import EsriMapView from "./app-views/EsriMapView";
import PanoImageryOne from "./app-views/PanoImageryOne";
import PointCloudTwo from './app-views/PointcloudTwo';
import PseudoLogin from "./app-views/PseudoLogin";

// Auth
import { Amplify } from 'aws-amplify';
import { amplifyConfig } from './api/auth/authConfig';
import { signInWithRedirect, signOut, fetchAuthSession } from 'aws-amplify/auth';
import { useEffect, useState } from "react";

Amplify.configure(amplifyConfig);

//const { username, userId, signInDetails } = await getCurrentUser();

const App = () => {
  const [auth, setAuth] = useState({});
  const [loading, setLoading] = useState(true);
  const [loginError, setLoginError] = useState(null);

  useEffect(() => {

    const fetchAuth = async () => {
      try {
        await fetchAuthSession().then(async (data) => {
          if (!data.tokens) {
            await signInWithRedirect({
              provider: { custom: "azureCognitoDevIdp" }
            })
          } // Redirect opens the page again, so we don't need to handle any response data, it's in auth_session after the redirect.
          // Authenticated by this point
          setAuth(data);
          // For now, to maintain compatibility with legacy code:
          const currentUser = { username: data.tokens?.idToken?.payload?.email };
          localStorage.setItem('currentUser', JSON.stringify(currentUser));
          setLoading(false);
        });
      } catch (error) {
        setLoginError("Login Error:" + JSON.stringify(error));
        console.error('AUTH::fetchAuthSession Error', err);
      }
    }

    setLoginError(null);
    setLoading(true);
    fetchAuth();
  }, [])

  if (loading || loginError) {
    return (
      <div>
        <p>
          {loginError ? loginError : ''}
        </p>
      </div>
    );
  }

  if (auth && auth.tokens) {
    return (
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<ProjectList />} />
          {/* <Route path="login" element={<PseudoLogin />} /> */}
          <Route path="login" element={<ProjectList />} />
          <Route path="map-one" element={<MapOne />} />
          <Route path="esri-map" element={<EsriMapView />} />
          <Route path="pano-imagery-one" element={<PanoImageryOne />} />
          <Route path="pointcloud-two" element={<PointCloudTwo />} />
        </Route>
      </Routes>
    );
  }
}

export default App;
