import React, { createContext, useContext } from 'react';
import {
    AssetApi,
    AssetWorkflowApi,
    GroundCollectionApi,
    PanoramaApi,
    PointCloudApi,
    ProjectApi,
    UserApi,
    WorkareaApi,
    WorkflowStatusApi
} from '../api';

const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
    const services = {
        assetApi: AssetApi,
        assetWorkflowApi: AssetWorkflowApi,
        groundCollectionApi: GroundCollectionApi,
        panoramaApi: PanoramaApi,
        pointCloudApi: PointCloudApi,
        projectApi: ProjectApi,
        userApi: UserApi,
        workareaApi: WorkareaApi,
        workflowStatusApi: WorkflowStatusApi,
    };

    return (
        <ApiContext.Provider value={services}>
            {children}
        </ApiContext.Provider>
    );
};

export const useApis = () => {
    return useContext(ApiContext);
};
