import { httpGet, httpPost, httpDelete } from "./auth/HttpClient";
const baseUrl = process.env.REACT_APP_BASE_ASSET_API_URL;
const useSecureApi = true;

const getAssetLocationsByType = async (assetTypeId) => {
    const url = `${baseUrl}/get-asset-locations-by-type?assetTypeId=${assetTypeId}`;
    return await httpGet(url, useSecureApi)
        .then(response => response.json())
        .then(data => { return data })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

const getAssetByUUID = async (asset_uuid) => {
    const url = `${baseUrl}/get-asset?assetId=${asset_uuid}`;
    return await httpGet(url, useSecureApi)
        .then(response => response.json())
        .then(data => { return data[0] })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

const getAttachedAssets = async (source_sys_ids, input_source_type_id) => {
    let body = {
        source_sys_ids,
        input_source_type_id
    }
    const url = `${baseUrl}/get-attached-assets`;
    const headers = new Headers();
    headers.append("Content-Type", 'application/json');
    return await httpPost(url, useSecureApi, {
        headers: headers,
        body: JSON.stringify(body)
    })
        .then(response => response.json())
        .then(data => { return data })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

const getInputSourceTypes = async () => {
    const url = `${baseUrl}/get-input-source-types`;
    return await httpGet(url, useSecureApi)
        .then(response => response.json())
        .then(data => { return data })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

const getAssetArchetypes = async () => {
    const url = `${baseUrl}/get-asset-archetypes`;
    return await httpGet(url, useSecureApi)
        .then(response => response.json())
        .then(data => { return data })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

const getAssetTypes = async () => {
    const url = `${baseUrl}/get-asset-types`;
    return await httpGet(url, useSecureApi)
        .then(response => response.json())
        .then(data => { return data })
        .catch(error => {
            throw new Error(`API call failed with status code ${error}`)
        });
}

const getJsonSchemaDefinitions = async () => {
    const url = `${baseUrl}/get-json-catalogs`;
    return await httpGet(url, useSecureApi)
        .then(response => response.json())
        .then(data => { return data })
        .catch(error => {
            console.log(`Failed to retireve Schemas`)
            throw new Error(`API call failed with status code ${error}`)
        });
}


const getJsonSchemaDefinition = async (schema_id) => {
    const url = `${baseUrl}/get-json-catalog?schemaId=${schema_id}`;
    return await httpGet(url, useSecureApi)
        .then(response => response.json())
        .then(data => { return data[0] })
        .catch(error => {
            console.log(`Failed to retireve Schema for schema id ${schema_id}`)
            throw new Error(`API call failed with status code ${error}`)
        });
}

const createAsset = async (asset) => {
    const url = `${baseUrl}/create-asset`;
    const headers = new Headers();
    headers.append("Content-Type", 'application/json');
    return await httpPost(url, useSecureApi, {
        headers: headers,
        body: JSON.stringify(asset)
    })
        .then(response => response.json())
        .then(data => { return data.asset_uuid })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
};

const updateAsset = async (asset) => {
    const url = `${baseUrl}/update-asset`;
    const headers = new Headers();
    headers.append("Content-Type", 'application/json');
    return await httpPost(url, useSecureApi, {
        headers: headers,
        body: JSON.stringify(asset)
    })
        .then(response => response.json())
        .then(data => { return data.asset_uuid })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
};

const updateAssetProperties = async (assetId, properties) => {
    const url = `${baseUrl}/update-asset-properties`;
    const headers = new Headers();
    headers.append("Content-Type", 'application/json');
    return await httpPost(url, useSecureApi, {
        headers: headers,
        body: {
            assetId: assetId,
            properties: JSON.stringify(properties)
        }
    })
        .then(response => response.json())
        .then(data => { return data.body })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

const deleteAsset = async (source_sys_id, input_soruce_type_id) => {
    const url = `${baseUrl}/delete-asset?source_sys_id=${source_sys_id}&input_source_type_id=${input_soruce_type_id}`;
    return await httpDelete(url, useSecureApi)
        .then(response => response.json())
        .then(data => { return data })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

const getPolesExport = async (assetUUIDs) => {
    const url = `${baseUrl}/spidacalc-export`;
    const headers = new Headers();
    headers.append("Content-Type", 'application/json');

    const response = await httpPost(url, useSecureApi, {
        headers: headers,
        body: JSON.stringify({
            poleIds: assetUUIDs
        })
    });

    if (!response.ok) throw new Error(`API call failed with status code ${response.status}`);

    const blob = await response.blob();
    return blob;
}


export {
    getAssetLocationsByType,
    getAssetByUUID,
    getAttachedAssets,
    getInputSourceTypes,
    getAssetArchetypes,
    getAssetTypes,
    getJsonSchemaDefinitions,
    getJsonSchemaDefinition,
    createAsset,
    updateAsset,
    updateAssetProperties,
    deleteAsset,
    getPolesExport
}