import { Outlet, useLocation, useNavigate } from "react-router-dom";
import smallLogo from './static/bv-logo-small.png';
import Sidebar from './custom-components/Sidebar.js';
import React, { useContext, useEffect } from 'react';
import {
  Container,
  Box,
  Toolbar,
  AppBar,
  Typography,
  IconButton,
  CssBaseline,
  styled,
  Menu,
  MenuItem,
  Snackbar,
  Alert,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { LayoutOption } from "./Enums";
import { userService } from "./services/index.js";
import { LayoutContext } from "./Contexts/LayoutContext.js";

const defaultDrawerWidthLeft = '20%';
const defaultDrawerWidthRight = '30%';

const Main = styled(Container, { shouldForwardProp: (prop) => prop !== 'leftSidebarOpen' && prop !== 'rightSidebarOpen' })
  (({ theme, leftSidebarOpen, rightSidebarOpen }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginTop: theme.spacing(8),
    width: `calc(100% - ${leftSidebarOpen ? defaultDrawerWidthLeft : '0px'} - ${rightSidebarOpen ? defaultDrawerWidthRight : '0px'})`,
  }));

const BVAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => !['leftSidebarOpen', 'rightSidebarOpen'].includes(prop),
})
  (({ theme, leftSidebarOpen, rightSidebarOpen }) => {
    // Adjustments for when both sidebars are open
    let appBarStyles = {
      width: `calc(100% - ${leftSidebarOpen ? defaultDrawerWidthLeft : '0px'} - ${rightSidebarOpen ? defaultDrawerWidthRight : '0px'})`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    };

    if (leftSidebarOpen) {
      appBarStyles.marginLeft = defaultDrawerWidthLeft;
      appBarStyles.transition = theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      });
    }

    if (rightSidebarOpen) {
      appBarStyles.marginRight = defaultDrawerWidthRight; // This ensures the AppBar shifts correctly when the right sidebar is open
    }

    return appBarStyles;
  });

export default function Layout() {
  const navigate = useNavigate();
  const location = useLocation();
  const { hash, pathname, search } = location;
  const layoutContext = useContext(LayoutContext);
  const [snackbar, setSnackbar] = layoutContext.snackbar;
  const [loading] = layoutContext.loading;
  const [layout] = layoutContext.layout;
  const [leftSidebarOpen, setLeftSidebarOpen] = React.useState(false);
  const [rightSidebarOpen, setRightSidebarOpen] = React.useState(false);
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  const [anchorEl, setAnchorEl] = React.useState(null);

  const appTitle = process.env.REACT_APP_TITLE;

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => { userService.Logout(); }

  const handleLeftSidebarOpen = () => {
    setLeftSidebarOpen(true);
  };

  const handleRightSidebarOpen = () => {
    setRightSidebarOpen(true);
  };

  useEffect(() => {

    document.title = appTitle;

    if (!currentUser && pathname !== '/login') {
      navigate('/login');
    }
  })

  useEffect(() => {
    switch (layout) {
      case LayoutOption.SideBarLeft:
        setLeftSidebarOpen(true);
        setRightSidebarOpen(false);
        break;
      case LayoutOption.SideBarRight:
        setLeftSidebarOpen(false);
        setRightSidebarOpen(true);
        break;
      case LayoutOption.SideBars:
        setLeftSidebarOpen(true);
        setRightSidebarOpen(true);
        break;
      default:
        setLeftSidebarOpen(false);
        setRightSidebarOpen(false);
        break;
    }
  }, [layout]);

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <BVAppBar position="fixed" leftSidebarOpen={leftSidebarOpen} rightSidebarOpen={rightSidebarOpen}>
          <Toolbar>
            {[LayoutOption.SideBarLeft, LayoutOption.SideBars].includes(layout) &&
              <IconButton
                color="inherit"
                aria-label="open left sidebar"
                onClick={handleLeftSidebarOpen}
                edge="start"
                sx={{ mr: 2, ...(leftSidebarOpen && { display: 'none' }) }}>
                <ChevronRightIcon />
              </IconButton>}
            <Box
              component="img"
              sx={{
                display: { xs: 'none', md: 'flex' },
                mr: 1,
                height: 40,
              }}
              alt="BV logo"
              src={smallLogo} />
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {/* Pole Insights */}
              {appTitle}
            </Typography>
            {currentUser && (
              <div>
                {currentUser.username}
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit">
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </div>
            )}
            {[LayoutOption.SideBarRight, LayoutOption.SideBars].includes(layout) &&
              <IconButton
                color="inherit"
                aria-label="open right sidebar"
                onClick={handleRightSidebarOpen}
                sx={{ mr: 2, ...(rightSidebarOpen && { display: 'none' }) }}
              >
                <ChevronLeftIcon />
              </IconButton>}
          </Toolbar>
        </BVAppBar>
        {[LayoutOption.SideBarLeft, LayoutOption.SideBars].includes(layout) &&
          <Sidebar open={leftSidebarOpen}
            onClose={setLeftSidebarOpen}
            side='left'
            drawerWidth={defaultDrawerWidthLeft} />
        }
        <Main leftSidebarOpen={leftSidebarOpen}
          rightSidebarOpen={rightSidebarOpen}
          className="main"
          disableGutters={true}
          maxWidth={false}>
          <Outlet />
        </Main>
        {[LayoutOption.SideBarRight, LayoutOption.SideBars].includes(layout) &&
          <Sidebar
            open={rightSidebarOpen}
            onClose={setRightSidebarOpen}
            side='right'
            drawerWidth={defaultDrawerWidthRight} />
        }
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={15000}
        onClose={() => setSnackbar({
          open: false,
          message: '',
          severity: 'success'
        })}>
        <Alert onClose={() => setSnackbar({
          open: false,
          message: '',
          severity: 'success'
        })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}